import {
  Command,
  EditableShapeNode,
  IApplication,
  ICommand,
  IDocument,
  INode,
  IShape,
  Logger,
  NodeLinkedList,
  PubSub,
  Result,
  Transaction,
  command,
} from "chili-core";
import { BJLoading } from "../../../chili-ui/src/components/bj-loading";
import { BJMouldInfo } from "chili-ui/src/components/bj-modal";
import { GenerateModelSourceDocument } from "./utils";
import { APP_BASE_URL } from "../../../network";

function getDemoFileContent2(): any {
  return new Promise((resolve, _) => {
      fetch("https://dzcdn.gzshibahao.com/cup%20mold.igs", {
          // mode:"no-cors"
      })
          .then((rep) => {
              console.log("rep: ", rep);
              return rep.blob();
          })
          .then((res) => {
              console.log("res: ", res);
              let reader = new FileReader();
              reader.onload = (e) => {
                  resolve(e.target!.result || "");
              };
              reader.readAsText(res);
          })
          .catch((err) => {
              console.log("err: ", err);
          });
  });
}

@command({
  name: "ai.operation",
  display: "ai.operation",
  icon: "icon-ai-operation",
})
export class AIMould implements ICommand {
application: IApplication = null!;
generateModelSourceDocument: GenerateModelSourceDocument
selectedNodes: INode[] = []
constructor() {
  this.generateModelSourceDocument = new GenerateModelSourceDocument()
}
async execute(application: IApplication): Promise<void> {
    this.application = application
    console.log("AIMould execute");
    const selectedNodes = application.activeView?.document.selection.getSelectedNodes() || [];
    console.log("selectedNodes: ", selectedNodes);
    if (selectedNodes.length === 0) {
        // alert("请先选择模型再进行ai运算")
        PubSub.default.pub("showToast", "toast.select.aiOperation");
        return;
    }

    const bjMouldInfo = new BJMouldInfo(application);
    bjMouldInfo.render(this);
    (window as any)._application = application
    this.selectedNodes = selectedNodes
    
    application.activeView?.document.selection.clearSelection()

    
    // application.executingCommand = undefined;
    // console.log('application: ', application);
}

async startOperation(application: IApplication) {
  const bjLoading = new BJLoading(application);
  bjLoading.render();
  bjLoading.startProgress();

  const modelSourceCode = await this.generateModelSourceDocument.execute(application, this.selectedNodes)

  try {
    
      const uploadRes = await window
      .fetch(`${APP_BASE_URL}/txt/upload`, {
          method: "POST",
          body: JSON.stringify(modelSourceCode),
          headers: {
              "Content-Type": "application/json",
          },
      })
      .then((rep) => {
          return rep.json();
      });
      console.log('uploadRes: ', uploadRes);
  } catch (error) {
      
  }

  const dels = document.querySelectorAll("tree-model");
  console.log("dels: ", dels);
  Array.from(dels).forEach(async (el: any) => {
      el.click();
      let commandCtor = Command.get("modify.delete")!;
      let command: any = new commandCtor();
      application.executingCommand = command;
      PubSub.default.pub("showProperties", application.activeView?.document!, []);
      await command
          .execute(application, false)
          .catch((err: any) => {
              Logger.error(err);
          })
          .finally(() => {
              application.executingCommand = undefined;
          });
  });

  const text = await getDemoFileContent2();
  // console.log("text: ", text);
  const file = new File([text], "text", { type: "text/plain" });
  let content = new Uint8Array(await file.arrayBuffer());
  // const shape = app.shapeFactory.converter.convertFromSTEP(text)
  const shape = application.shapeFactory.converter.convertFromIGES(content);
  // console.log("shape: ", shape);

  Transaction.excute(application.activeView?.document!, "import model", () => {
      this.addImportedShape(application.activeView?.document!, ["ai-experimental", shape], () => {
          bjLoading.endProgress();
      });
  });

  application.activeView?.cameraController.fitContent();
}

zoomCanvas(index = 0) {
    // console.log('zoomCanvas index: ', index);
    if (!index) return;
    const view = (window as any)._viewWheel;
    view.cameraController.zoom(631, 403, 111);
    view.update();
    setTimeout(() => {
        this.zoomCanvas(--index);
    }, 16);
}

private addImportedShape = (
    document: IDocument,
    shape: [string | undefined, Result<IShape[]>],
    endCallback: () => any,
) => {
    if (!shape[1].isOk) {
        PubSub.default.pub("showToast", "toast.read.error");
        return;
    }
    let index = 1;

    let shapes = shape[1].value.map((x) => {
        return new EditableShapeNode(document, `Imported ${index++}`, x);
    });

    console.log("shapes: ", shapes);
    let nodeList = new NodeLinkedList(document, shape[0]!);
    // console.log('nodeList: ', nodeList);
    document.addNode(nodeList);
    // console.log('addNode')
    // nodeList.add(...shapes);
    // document.visual.update();

    this.zoomCanvas(65);

    function _runTask(index = 0) {
        if (index >= shapes.length) {
            endCallback();
            console.log("渲染完成");
            return;
        }
        requestIdleCallback((deadline) => {
            // console.log('deadline.timeRemaining(): ', deadline.timeRemaining());
            if (deadline.timeRemaining() >= 7) {
                nodeList.add(...shapes.slice(index, index + 6));
                // console.log('add')
                document.visual.update();
                // console.log('update')
                index += 6;
                _runTask(index);
            } else {
                _runTask(index);
            }
        });
    }
    _runTask();
};
}
