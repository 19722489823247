import { IApplication } from "chili-core";
import renderBJMouldInfoUI from "./ui-moduld-info";
import { AIMould } from "chili/src/commands/aiMould";

export class BJMouldInfo extends HTMLElement {
  constructor(readonly app: IApplication) {
    super();
    // this.className = style.wrapper;
  }

  render(AIMouldInstantiate: AIMould) {
    renderBJMouldInfoUI(this, AIMouldInstantiate);
    document.body.appendChild(this);
  }
}
customElements.define("wbj-modal", BJMouldInfo);
