import React, { useState } from 'react';
import ReactDom from 'react-dom/client';
import { flushSync } from 'react-dom';
import CSS from './bj-mould-info.module.css'
import { AIMould } from 'chili/src/commands/aiMould';
import { PubSub } from 'chili-core';
const tdLabelStyle = {
  width: '200px'
}
const tdValueStyle = {
  minWidth: '200px'
}

function BJMouldInfoUI({unmount, AIMouldInstantiate}: {unmount: () => void, AIMouldInstantiate: AIMould}) {

  const [formData, seFormData] = useState<any>({
    toolLife: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    moldType: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    specialStructure: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    moldBaseSteel: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    moldBaseSupplier: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    steelGradeAndHardness: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    steelGradeAndHardness2: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    sliderNo: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    sliderNo2: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    texture: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    texture2: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    // 第二部分数据
    plasticMaterial: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    shrinkFactor: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    totalCavities: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    gateType: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    gatesPerCavity: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    runnerSystem: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    hotRunnerBrand: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    liftersPerCavity: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    partWeight: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    runningWeight: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    injectionMachine: {
      value: '',
      required: true,
      ref: {
        current: null
      }
    },
    // 第三部分数据
    theReckoner: {
      value: '',
      required: false,
      ref: {
        current: null
      }
    },
    dateChapter: {
      value: '',
      required: false,
      ref: {
        current: null
      }
    },
    copyrightSeal: {
      value: '',
      required: false,
      ref: {
        current: null
      }
    },
    cavityNumber: {
      value: '',
      required: false,
      ref: {
        current: null
      }
    },
    cycleChapter: {
      value: '',
      required: false,
      ref: {
        current: null
      }
    },
    materialIdentification: {
      value: '',
      required: false,
      ref: {
        current: null
      }
    },
  })

  const startAIOperationHandler = () => {
    if (!verifyFormData()) return
    unmount()
    AIMouldInstantiate.startOperation(AIMouldInstantiate.application)
  }
  
  const toolLifeBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!value || typeof Number(value) !== 'number') {
      PubSub.default.pub("showToast", "toast.supportOnlyNumbers");
      return
    }

     const simulateAwait = async (time: number) => {
      return new Promise(res => {
        setTimeout(() => {
          res(true)
        }, time);
      })
    }
    
    PubSub.default.pub(
      "showPermanent",
      async () => {
        await simulateAwait(3000)
        
        PubSub.default.pub("showToast", "toast.generatingMoldDataIsComplete");
        
        flushSync(() => {
          seFormData((state: any) => {
            state.toolLife.value = value
            state.moldType.value = '热流道'
            state.specialStructure.value = '圆杆斜顶'
            state.moldBaseSteel.value = 'S50C'
            state.moldBaseSupplier.value = 'XXXX'
            state.steelGradeAndHardness.value = 'P20'
            state.steelGradeAndHardness2.value = '718'
            state.sliderNo.value = '1'
            state.sliderNo2.value = '1'
            state.texture.value = '抛光'
            state.texture2.value = '火花纹'
  
            state.plasticMaterial.value = 'SAN'
            state.shrinkFactor.value = '0.4%'
            state.totalCavities.value = '4'
            state.gateType.value = '直浇口'
            state.gatesPerCavity.value = '1'
            state.runnerSystem.value = '分流道'
            state.hotRunnerBrand.value = 'YUDO'
            state.liftersPerCavity.value = '0'
            state.partWeight.value = '25'
            state.runningWeight.value = '18'
            state.injectionMachine.value = 'NEX-Ⅳ series'
  
            state.theReckoner.value = 'MISUMI'
            state.dateChapter.value = 'DT1M6'
            return {...state}
          })
        })
        verifyFormData()
      },
      "toast.excuting{0}",
      "生成模具数据"
    );

  }

  const verifyFormData = () => {
    let flag = true
    for (const key in formData) {
      const ref = formData[key].ref?.current
      formData[key].value = ref?.value
      if (formData[key].required && !formData[key].value) {
        PubSub.default.pub("showToast", "toast.required.fillOut");
        ref.parentNode.style = "border: 1px solid #ff4d4f;"
        flag = false
      } else if (ref.parentNode.style.border) {
        ref.parentNode.removeAttribute('style')
      }
    }
    return flag
  }

  return <div className={CSS.modal}>
    <div className={CSS.content}>
      <header className={CSS.header}>
        <button className={CSS.satrt_ai_btn} onClick={startAIOperationHandler}>确认无误，开始AI运算</button>
        <span className={CSS.off} onClick={unmount}>X</span>
      </header>
      <div className={CSS.table_box}>
        {/* 第一部分 */}
        <table border={1} cellPadding="5">
          <tbody>
            <tr>
              <td style={tdLabelStyle}>Tool Life (模具寿命)</td>
              <td colSpan={2} style={tdValueStyle}>
                <input type="number" placeholder='请输入模具寿命（单位：W）' ref={formData.toolLife.ref} onBlur={toolLifeBlurHandler} defaultValue={formData.toolLife.value} />
              </td>
            </tr>
            <tr>
              <td>Mold Type (模具类型)</td>
              <td colSpan={2}>
                <input type="text" ref={formData.moldType.ref} disabled={!formData.toolLife.value} defaultValue={formData.moldType.value} />
              </td>
            </tr>
            <tr>
              <td>Special Structure<br/>(特殊结构：如倒装/跳板轴芯/内滑块/母模斜顶等)</td>
              <td colSpan={2}>
                <input type="text" ref={formData.specialStructure.ref}  disabled={!formData.toolLife.value} defaultValue={formData.specialStructure.value} />
              </td>
            </tr>
            <tr>
              <td>Mold Base Steel<br/>(模胚钢料)</td>
              <td colSpan={2}>
                <input type="text" ref={formData.moldBaseSteel.ref} disabled={!formData.toolLife.value} defaultValue={formData.moldBaseSteel.value} />
              </td>
            </tr>
            <tr>
              <td>Mold Base Supplier<br/>(模胚供应商)</td>
              <td colSpan={2}>
                <input type="text" ref={formData.moldBaseSupplier.ref} disabled={!formData.toolLife.value} defaultValue={formData.moldBaseSupplier.value} />
              </td>
            </tr>
            <tr>
              <td>Steel Grade And Hardness</td>
              <td style={{width: "80px"}}>Cavity</td>
              <td>
                <input type="text" ref={formData.steelGradeAndHardness.ref} disabled={!formData.toolLife.value} defaultValue={formData.steelGradeAndHardness.value} />
              </td>
            </tr>
            <tr>
              <td>(模仁钢材及硬度)</td>
              <td>Core</td>
              <td>
                <input type="text" ref={formData.steelGradeAndHardness2.ref} disabled={!formData.toolLife.value} defaultValue={formData.steelGradeAndHardness2.value} />
              </td>
            </tr>
            <tr>
              <td>Slider No. </td>
              <td>Cavity</td>
              <td>
                <input type="text" ref={formData.sliderNo.ref} disabled={!formData.toolLife.value} defaultValue={formData.sliderNo.value} />
              </td>
            </tr>
            <tr>
              <td>(行位数)</td>
              <td>Core</td>
              <td>
                <input type="text" ref={formData.sliderNo2.ref} disabled={!formData.toolLife.value} defaultValue={formData.sliderNo2.value} />
              </td>
            </tr>
            <tr>
              <td>Texture</td>
              <td>Cavity</td>
              <td>
                <input type="text" ref={formData.texture.ref} disabled={!formData.toolLife.value} defaultValue={formData.texture.value} />
              </td>
            </tr>
            <tr>
              <td>(模仁表面处理)</td>
              <td>Core</td>
              <td>
                <input type="text" ref={formData.texture2.ref} disabled={!formData.toolLife.value} defaultValue={formData.texture2.value} />
              </td>
            </tr>
          </tbody>
        </table>

        {/* 第二部分 */}
        <table border={1} cellPadding="5">
          <tbody>
            <tr>
              <td style={tdLabelStyle}>Plastic Material (塑胶材料)</td>
              <td style={tdValueStyle}>
                <input type="text" ref={formData.plasticMaterial.ref} disabled={!formData.toolLife.value} defaultValue={formData.plasticMaterial.value} />
              </td>
            </tr>
            <tr>
              <td>Shrink Factor (缩水率)</td>
              <td>
                <input type="text" ref={formData.shrinkFactor.ref} disabled={!formData.toolLife.value} defaultValue={formData.shrinkFactor.value} />
              </td>
            </tr>
            <tr>
              <td>Total Cavities (模穴数)</td>
              <td>
                <input type="text" ref={formData.totalCavities.ref} disabled={!formData.toolLife.value} defaultValue={formData.totalCavities.value} />
              </td>
            </tr>
            <tr>
              <td>Gate Type (浇口类型)</td>
              <td>
                <input type="text" ref={formData.gateType.ref} disabled={!formData.toolLife.value} defaultValue={formData.gateType.value} />
              </td>
            </tr>
            <tr>
              <td>Gates per Cavity<br/>(每穴浇口数量)</td>
              <td>
                <input type="text" ref={formData.gatesPerCavity.ref} disabled={!formData.toolLife.value} defaultValue={formData.gatesPerCavity.value} />
              </td>
            </tr>
            <tr>
              <td>Runner System (流道系统)</td>
              <td>
                <input type="text" ref={formData.runnerSystem.ref} disabled={!formData.toolLife.value} defaultValue={formData.runnerSystem.value} />
              </td>
            </tr>
            <tr>
              <td>Hot Runner Brand<br/>(热流道品牌)</td>
              <td>
                <input type="text" ref={formData.hotRunnerBrand.ref} disabled={!formData.toolLife.value} defaultValue={formData.hotRunnerBrand.value} />
              </td>
            </tr>
            <tr>
              <td>Lifters per Cavity<br/>(每穴斜顶数量)</td>
              <td>
                <input type="text" ref={formData.liftersPerCavity.ref} disabled={!formData.toolLife.value} defaultValue={formData.liftersPerCavity.value} />
              </td>
            </tr>
            <tr>
              <td>Part Weight (产品单重)</td>
              <td>
                <input type="text" ref={formData.partWeight.ref} disabled={!formData.toolLife.value} defaultValue={formData.partWeight.value} />
              </td>
            </tr>
            <tr>
              <td>Running Weight (流道重量)</td>
              <td>
                <input type="text" ref={formData.runningWeight.ref} disabled={!formData.toolLife.value} defaultValue={formData.runningWeight.value} />
              </td>
            </tr>
            <tr>
              <td>Injection Machine<br/>(注塑机型号)</td>
              <td>
                <input type="text" ref={formData.injectionMachine.ref} disabled={!formData.toolLife.value} defaultValue={formData.injectionMachine.value} />
              </td>
            </tr>
          </tbody>
        </table>

        {/* 第三部分 */}
        <table border={1} cellPadding="5">
          <tbody>
            <tr>
              <td style={tdLabelStyle}>计数器</td>
              <td style={tdValueStyle}>
                <input type="text" ref={formData.theReckoner.ref} disabled={!formData.toolLife.value} defaultValue={formData.theReckoner.value} />
              </td>
            </tr>
            <tr>
              <td>年月章</td>
              <td>
                <input type="text" ref={formData.dateChapter.ref} disabled={!formData.toolLife.value} defaultValue={formData.dateChapter.value} />
              </td>
            </tr>
            <tr>
              <td>版权章</td>
              <td>
                <input type="text" ref={formData.copyrightSeal.ref} disabled={!formData.toolLife.value} defaultValue={formData.copyrightSeal.value} />
              </td>
            </tr>
            <tr>
              <td>模穴号</td>
              <td>
                <input type="text" ref={formData.cavityNumber.ref} disabled={!formData.toolLife.value} defaultValue={formData.cavityNumber.value} />
              </td>
            </tr>
            <tr>
              <td>循环章</td>
              <td>
                <input type="text" ref={formData.cycleChapter.ref} disabled={!formData.toolLife.value} defaultValue={formData.cycleChapter.value} />
              </td>
            </tr>
            <tr>
              <td>材料标识</td>
              <td>
                <input type="text" ref={formData.materialIdentification.ref} disabled={!formData.toolLife.value} defaultValue={formData.materialIdentification.value} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
}

export default function renderBJMouldInfoUI(dom: HTMLElement, AIMouldInstantiate: AIMould) {
  const root = ReactDom.createRoot(dom)

  const unmount = () => {
    root?.unmount();
    dom?.remove()
  }

  root.render(<BJMouldInfoUI unmount={unmount} AIMouldInstantiate={AIMouldInstantiate} />)
}