import { AsyncController, IApplication, INode, IShape, PubSub, Result, ShapeNode, VisualNode } from "chili-core";
import { SelectShapeNodeStep } from "../../step";

export class GenerateModelSourceDocument {
  models = []
  async execute(application: IApplication, selectedNodes: INode[]) {
    if (!application.activeView?.document) return;
    
    let models = await this.selectModelsAsync(application, selectedNodes);
    if (!models || models.length === 0) {
        PubSub.default.pub("showToast", "toast.select.noSelected");
        return;
    }
  
  //   PubSub.default.pub(
  //       "showPermanent",
  //       async () => {
  //           let shapes = models!.map((x) => x.geometry.shape.value!);
  //           let shapeString = await this.convertAsync(application, 'iges', ...shapes);
  //           if (!shapeString.isOk) {
  //               PubSub.default.pub("showToast", "toast.converter.error");
  //               return;
  //           }
  //           PubSub.default.pub("showToast", "toast.downloading");
  //           download([shapeString.value], `${models![0].name}.${type}`);
  //       },
  //       "toast.excuting{0}",
  //       "",
  //   );
  
    let shapes = models.map((x) => (x as ShapeNode).shape.value);
    let shapeString = await this.convertAsync(application, 'iges', ...shapes);
    if (!shapeString.isOk) {
        PubSub.default.pub("showToast", "toast.converter.error");
        return;
    }
    return { value: shapeString.value, type: "iges" };
  }
  
  private async convertAsync(
      application: IApplication,
      type: string,
      ...shapes: IShape[]
  ): Promise<Result<string>> {
      await new Promise((r, j) => {
          setTimeout(r, 50);
      }); // 等待弹窗完成
      return type === "iges"
          ? application.shapeFactory.converter.convertToIGES(...shapes)
          : application.shapeFactory.converter.convertToSTEP(...shapes);
  }

  private async selectModelsAsync(application: IApplication, selectedNodes: INode[]) {
    // let models = application.activeView?.document.selection
    //     .getSelectedNodes()
    //     .filter((x) => x instanceof VisualNode);
        let models = selectedNodes.filter((x) => x instanceof VisualNode);
    if (models?.length === 0) {
        let controller = new AsyncController();
        let step = new SelectShapeNodeStep("prompt.select.models", true);
        let data = await step.execute(application.activeView?.document!, controller);
        if (!data?.nodes) {
            PubSub.default.pub("showToast", "prompt.select.noModelSelected");
            return;
        }
        models = data.nodes;
    }
    return models;
  }
}
