
const environment = process.env['NODE_ENV']
let APP_BASE_URL = '/api'
const SERVER_BASE_URL = 'https://mold.daozhong.com.cn'
if (environment === 'production') {
  APP_BASE_URL = SERVER_BASE_URL
} else if (environment === 'development') {
  APP_BASE_URL = '/api'
}

export {
  APP_BASE_URL,
  SERVER_BASE_URL
}
