// Copyright 2022-2023 the Chili authors. All rights reserved. AGPL-3.0 license.

import { Locale } from "./i18n";

export default {
    display: "简体中文",
    code: "zh-CN",
    translation: {
        "arc.angle": "角度",
        "arc.start": "起点",
        "axis.x": "X 轴",
        "axis.y": "Y 轴",
        "axis.z": "Z 轴",
        "body.arc": "圆弧",
        "body.bolean": "布尔",
        "body.box": "立方体",
        "body.circle": "圆形",
        "body.face": "面",
        "body.fuse": "合并",
        "body.imported": "导入的模型",
        "body.line": "直线",
        "body.polygon": "多边形",
        "body.prism": "拉伸",
        "body.rect": "矩形",
        "body.revol": "旋转",
        "body.sweep": "扫略",
        "body.wire": "线框",
        "body.editableShape": "可编辑的形状",
        "body.meshNode": "网格节点",
        "box.dx": "长",
        "box.dy": "宽",
        "box.dz": "高",
        "circle.center": "圆心",
        "circle.radius": "半径",
        "command.arc": "圆弧",
        "command.array": "阵列",
        "command.bezier": "贝塞尔",
        "command.boolean.common": "相交",
        "command.boolean.cut": "剪切",
        "command.boolean.fuse": "融合",
        "command.box": "立方体",
        "command.break": "打断",
        "command.circle": "圆",
        "command.copy": "复制",
        "command.delete": "删除",
        "command.document.new": "新建文档",
        "command.document.open": "打开文档",
        "command.document.save": "保存文档",
        "command.document.saveAs": "另存为",
        "command.document.saveToFile": "保存到文件",
        "command.export": "导出",
        "command.faceable.isFace": "面",
        "command.fuse": "合并",
        "command.import": "导入",
        "command.line.isConnected": "相连",
        "command.line": "直线",
        "command.mirror": "镜像",
        "command.mode.repeat": "重复",
        "command.move": "移动",
        "command.newFolder": "新建文件夹",
        "command.newGroup": "新建组",
        "command.offset": "偏移",
        "command.polygon": "多边形",
        "command.prism": "拉伸",
        "command.rect": "矩形",
        "command.redo": "重做",
        "command.revol": "旋转",
        "command.rotate": "旋转",
        "command.section": "相交线",
        "command.split": "分割",
        "command.sweep": "扫略",
        "command.thickSolid": "抽壳",
        "command.toFace": "转面",
        "command.toWire": "转多段线",
        "command.trim": "修剪",
        "command.undo": "撤销",
        "common.angle": "角度",
        "common.back": "返回",
        "common.cancel": "取消",
        "common.clone": "复制对象",
        "common.color": "颜色",
        "common.confirm": "确定",
        "common.general": "常规",
        "common.length": "长度",
        "common.material": "材质",
        "common.matrix": "矩阵",
        "common.name": "名称",
        "common.normal": "法向量",
        "common.opacity": "不透明度",
        "common.thickness": "厚度",
        "common.type": "类型",
        "entity.editable": "可编辑实体",
        "entity.parameter": "参数化实体",
        "error.default": "错误",
        "error.input.cannotInputANumber": "与参照点重合，无法输入 1 个数",
        "error.input.invalidNumber": "输入错误，请输入有效的数字，以,分开",
        "error.input.threeNumberCanBeInput": "参照点为空，只能输入 3 个数",
        "error.input.unsupportedInputs": "超过最大输入数",
        "file.format": "文件格式",
        "home.recent": "最近使用",
        "home.welcome": "欢迎使用 AI模具",
        "items.header": "项目",
        "items.tool.delete": "删除",
        "items.tool.expandAll": "展开所有",
        "items.tool.newFolder": "文件夹",
        "items.tool.unexpandAll": "折叠所有",
        "line.end": "终点",
        "line.start": "起点",
        "line.type.line": "直线",
        "line.type.xline": "构造线",
        "material.repeatU": "U 重复",
        "material.repeatV": "V 重复",
        "material.texture": "贴图",
        "model.visible": "可见",
        "operate.pickCircleCenter": "请选择圆心  按 ESC 键取消",
        "operate.pickFistPoint": "请选择第一个点, 按 ESC 键取消",
        "operate.pickNextPoint": "请选择下一个点， 按 ESC 键取消",
        "operate.pickRadius": "请选择半径， 按 ESC 键取消",
        "polygon.points": "点",
        "prompt.default": "鼠标中键平移视图，Shift + 中键旋转视图，中键滚动缩放视图",
        "prompt.deleteDocument{0}": "是否删除 {0} ？",
        "prompt.polygon.close": "闭合",
        "prompt.saveDocument{0}": "是否保存对 {0} 的更改？",
        "prompt.select.edges": "请选择边",
        "prompt.select.faces": "请选择面",
        "prompt.select.models": "请选择模型",
        "prompt.select.noModelSelected": "未选择任何模型",
        "prompt.select.shape": "选择形状",
        "prompt.select.vertexs": "请选择点",
        "prompt.select.wires": "请选择线",
        "properties.group.transform": "转换",
        "properties.header": "属性",
        "properties.multivalue": "多个值",
        "rect.dx": "长",
        "rect.dy": "宽",
        "ribbon.group.boolean": "布尔运算",
        "ribbon.group.converter": "转换",
        "ribbon.group.draw": "绘制",
        "ribbon.group.importExport": "导入/导出",
        "ribbon.group.modify": "修改",
        "ribbon.group.selection": "选择",
        "ribbon.group.tools": "工具",
        "ribbon.group.workingPlane": "工作平面",
        "ribbon.tab.draw": "绘图",
        "ribbon.tab.file": "文件",
        "ribbon.tab.startup": "开始",
        "snap.center": "圆心",
        "snap.end": "端点",
        "snap.intersection": "交点",
        "snap.mid": "中点",
        "snap.perpendicular": "垂点",
        "snap.nearest": "最近点",
        "toast.command.{0}excuting": "{0}命令正在执行",
        "toast.converter.error": "转换错误",
        "toast.converter.invalidColor": "无效的颜色",
        "toast.delete{0}Objects": "删除了 {0} 个对象",
        "toast.document.noActived": "未打开任何文档",
        "toast.document.saved": "文档已保存",
        "toast.downloading": "正在下载",
        "toast.excuting{0}": "正在执行{0}",
        "toast.fail": "失败",
        "toast.read.error": "读取错误",
        "toast.select.noSelected": "未选择任何对象",
        "toast.success": "成功",
        "transform.rotation": "旋转",
        "transform.scale": "缩放",
        "transform.translation": "位移",
        "vertex.point": "点",
        "workingPlane.alignToPlane": "对齐到平面",
        "workingPlane.set": "设置工作平面",
        "test.performace": "性能测试",
        "ribbon.group.ai": "AI",
        "ai.operation": "ai运算",
        "toast.select.aiOperation": "请先选择模型再进行ai运算",
        "toast.ai.operationCompletion": "ai运算已完成",
        "toast.supportOnlyNumbers": "请输入数字",
        "toast.generatingMoldDataIsComplete": "数据已生成完毕，如错误可自行修改",
        "toast.required.fillOut": "请输入内容至红框",
    },
} satisfies Locale;
