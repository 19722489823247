import { IApplication, PubSub } from "chili-core";
import { div } from "../../components";
import style from "./bj-loading.module.css";

export class BJLoading extends HTMLElement {
    progress = 0;
    progressInterval = null;

    progressRef = {
        current: null,
    };

    constructor(readonly app: IApplication) {
        super();
        this.className = style.wrapper;
    }

    render() {
        this.append(
            div({
                className: style.loading_text,
                textContent: "ai模型正在运算中...",
            }),
            div(
                {
                    className: style.progress_container,
                },
                div({
                    ref: this.progressRef,
                    className: style.progress_bar,
                    textContent: this.progress + "%",
                }),
            ),
        );
        document.body.appendChild(this);
    }

    fakeProgress() {
        const progressBar = this.progressRef.current! as HTMLElement;
        // console.log('progressBar: ', progressBar);
        if (this.progress < 30) {
            this.progress += Math.random() * 0.7; // 快速增长
        } else if (this.progress < 93) {
            this.progress += Math.random() * 0.2; // 最后缓慢增长
        }
        if (this.progress >= 99) {
            this.progress = 99; // 确保不会超过99%
        }
        progressBar.style.width = this.progress + "%";
        progressBar.innerHTML = Math.floor(this.progress) + "%";
    }

    completeProgress() {
        const progressBar = this.progressRef.current! as HTMLElement;
        if (this.progress < 100) {
            this.progress += 1; // 缓慢增加到100
            progressBar.style.width = this.progress + "%";
            progressBar.innerHTML = Math.floor(this.progress) + "%";
            setTimeout(() => this.completeProgress(), 50); // 每50毫秒更新一次
        } else {
            setTimeout(() => {
                this.remove();
                PubSub.default.pub("showToast", "toast.ai.operationCompletion");
            }, 500);
        }
    }

    startProgress() {
        (this.progressInterval as any) = setInterval(() => {
            this.fakeProgress();
        }, 100); // 每100毫秒更新一次进度
        // requestIdleCallback(deadline => {
        //   if (deadline.timeRemaining() > 0) {
        //     this.fakeProgress()
        //   } else {
        //     setTimeout(() => {
        //       this.startProgress()
        //     }, 100);
        //   }
        // })
    }

    endProgress() {
        clearInterval(this.progressInterval as any);
        this.completeProgress(); // 缓慢增加到100%
    }
}

customElements.define("wbj-loading", BJLoading);
